import styled from 'styled-components';
import tw from 'twin.macro';
import { Heading3 } from '../../Typography/Typography.react';
import Button from '../Button/Button.react';

export const StyledSubNavBlock = styled.div`
	${tw`
      bg-purple-100
      shadow-md
			py-4 md:py-8 
			mx-auto md:ml-1/12
      w-7/8 md:w-10/12
      p-4
      relative
      z-10
    `}
`;
export const SubNavHeading = styled(Heading3)`
	${tw`
    `}
`;

export const SubNav = styled.div`
	${tw`
  w-full
  pt-2
  grid  grid-cols-2 md:grid-cols-3 gap-2 md:gap-4
    `}
`;

export const SubnavButton = styled(Button)`
	${tw`
  mt-0
grid-flow-col

    `}
`;
