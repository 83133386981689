import React from 'react';
import { StyledSubNavBlock, SubNavHeading, SubnavButton, SubNav } from './SubnavBlock.styles';

const SubnavBlock = ({ title, body, subnav, className, ...props }) => (
	<StyledSubNavBlock className={className} {...props}>
		<SubNavHeading>Galleries in {title} </SubNavHeading>
		<p>{body}</p>
		<SubNav>
			{subnav.map(({ buttonLink, buttonText }) => (
				<SubnavButton className={className} buttonLink={buttonLink} buttonText={buttonText} />
			))}
		</SubNav>
	</StyledSubNavBlock>
);

export default SubnavBlock;
